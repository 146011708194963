import {
    BasePlanDetails,
    Partner,
    PlanDetails,
    ProductType,
    User,
    VerifiedPlanDetails,
} from '@soluto-private/mx-types';

export type ContextKey = Key | string;

export enum Key {
    Client = 'mxclient',
    Plans = 'mxplans',
    Services = 'mxservices',
    Language = 'mxlang',
    ProductType = 'mxproducttype',
    User = 'mxuser',
    UnverifiedPlans = 'mxunverifiedplans',
    VerifiedPlans = 'mxverifiedplans',
    TerminatedPlans = 'mxterminatedplans',
    FilteredPartners = 'mxfilteredpartners',
    Layout = 'mx-layout',
}

export type ValidatorFunc<T> = (val: T) => boolean;

/**
 * Describes storage record access and lifecycle
 */
export type StorageOptions = {
    /**
     * Define when the record will be removed. Value can be a Number
     * which will be interpreted as days from time of creation or a
     * Date instance. If omitted, the record becomes a session record.
     */
    expires?: number | Date;
    validatorFunc?: () => {};
    [option: string]: unknown;
};

export type Storage = {
    /**
     * Should dispatch ONESERVICE_CONTEXT_UPDATED and `ONESERVICE_CONTEXT_${key}_UPDATED` events
     * on successful record creation/update
     *
     * @param key
     * @param value
     * @param opts      Record access and lifecycle options.
     */
    set<T>(
        key: ContextKey,
        value: T,
        validatorFunc?: ValidatorFunc<T>,
        opts?: StorageOptions
    ): void;
    get(key: Key.Client): Partner;
    get(key: Key.Language): string;
    get(key: Key.Plans): BasePlanDetails[];
    get(key: Key.Services): string[];
    get(key: Key.ProductType): ProductType;
    get(key: Key.User): User;
    get(key: Key.UnverifiedPlans | Key.TerminatedPlans): PlanDetails[];
    get(key: Key.VerifiedPlans): VerifiedPlanDetails[];
    get(key: Key.FilteredPartners): Partner[];
    get<T>(key: ContextKey, validatorFunc?: ValidatorFunc<T>): T;
    remove(Key: ContextKey): void;
    removeAll(): void;
};
