import {
    CustomAttribute,
    CustomProps,
    Layout,
    Route,
    RouteType,
} from '@soluto-private/mx-types';
import { ValidationResult, baseValidator } from './baseValidator';

import { z } from 'zod';

const CustomPropsSchema: z.ZodSchema<CustomProps> = z.object({});

const CustomAttributeSchema: z.ZodSchema<CustomAttribute> = z.object(
    {
        name: z.string(),
        value: z.string(),
    },
    { description: 'CustomAttributeSchema' }
);

const RouteSchema: z.ZodSchema<Route> = z.object(
    {
        type: z.nativeEnum(RouteType),
        routes: z.lazy(() => RouteSchema.array().optional()),
        path: z.string().optional(),
        name: z.string().optional(),
        props: CustomPropsSchema.optional(),
        attrs: CustomAttributeSchema.array().optional(),
    },
    { description: 'RouteSchema' }
);

const LayoutSchema: z.ZodSchema<Layout> = z.object(
    {
        routes: RouteSchema.array(),
        containerEl: z.string(),
    },
    { description: 'LayoutSchema' }
);

export const layoutValidator = (value: unknown): ValidationResult =>
    baseValidator(LayoutSchema, value);
