import {
    Agreement,
    AgreementCustomer,
    BasePlanDetails,
    ClientAccount,
    Partner,
    PlanDetails,
    ProductType,
    Purchase,
    Subscription,
    UserPlanDetails,
    UserPlansServices,
    VerifiedPlanDetails,
    VerifiedSubscription,
} from '@soluto-private/mx-types';
import { ValidationResult, baseValidator } from './baseValidator';

import { z } from 'zod';

export const PartnerSchema = z.nativeEnum(Partner, {
    description: 'PartnerSchema',
});

export const partnerValidator = (value: unknown): ValidationResult =>
    baseValidator(PartnerSchema, value);

export const filteredPartnersValidator = (value: unknown): ValidationResult =>
    baseValidator(PartnerSchema, value, true);

const PurchaseSchema: z.ZodSchema<Purchase> = z.object(
    {
        RetailName: z.string(),
        RetailSKU: z.string(),
        PurchaseDate: z.string(),
    },
    { description: 'PurchaseSchema' }
);
const AgreementCustomerSchema: z.ZodSchema<AgreementCustomer> = z.object(
    {
        FirstName: z.string().optional(),
        LastName: z.string().optional(),
        Email: z.string().optional(),
    },
    { description: 'AgreementCustomerSchema' }
);

const ClientAccountSchema: z.ZodSchema<ClientAccount> = z.object(
    {
        ClientChannelId: z.string(),
        Type: z.string(),
    },
    { description: 'ClientAccountSchema' }
);

const AgreementSchema: z.ZodSchema<Agreement> = z.object(
    {
        Id: z.string(),
        ClientAccount: ClientAccountSchema,
        SubscriptionNumber: z.string(),
        Purchase: PurchaseSchema.optional(),
        Status: z.string(),
        Customers: z.array(AgreementCustomerSchema).optional(),
        TerminationDate: z.string().optional(),
    },
    { description: 'AgreementSchema' }
);

export const BasePlanDetailsSchema: z.ZodSchema<BasePlanDetails> = z.object(
    {
        id: z.string(),
        plan: z.string(),
        productType: z.nativeEnum(ProductType),
        displayName: z.string(),
    },
    { description: 'BasePlanDetailsSchema' }
);

export const basePlanDetailsValidator = (value: unknown): ValidationResult =>
    baseValidator(BasePlanDetailsSchema, value, true);

const PlanDetailsSchema: z.ZodSchema<PlanDetails> = z.object(
    {
        plan: BasePlanDetailsSchema,
        agreement: AgreementSchema,
    },
    { description: 'PlanDetailsSchema' }
);

export const unverifiedPlansValidator = (value: unknown): ValidationResult =>
    baseValidator(PlanDetailsSchema, value, true);

export const terminatedPlansValidator = (value: unknown): ValidationResult =>
    baseValidator(PlanDetailsSchema, value, true);

const VerifiedPlanDetailsSchema: z.ZodSchema<VerifiedPlanDetails> =
    PlanDetailsSchema.and(
        z.object(
            {
                verifiedThisSession: z.boolean(),
            },
            { description: 'VerifiedPlanDetailsSchema' }
        )
    );

export const verifiedPlansValidator = (value: unknown): ValidationResult =>
    baseValidator(VerifiedPlanDetailsSchema, value, true);

const SubscriptionSchema: z.ZodSchema<Subscription> = z.object(
    {
        clientChannelId: z.string(),
        subscriptionNumber: z.string(),
    },
    { description: 'SubscriptionSchema' }
);

const VerifiedSubscriptionSchema: z.ZodSchema<VerifiedSubscription> =
    SubscriptionSchema.and(
        z.object(
            {
                verifiedThisSession: z.boolean(),
            },
            { description: 'VerifiedSubscriptionSchema' }
        )
    );

export const verifiedSubscriptionValidator = (
    value: unknown
): ValidationResult => baseValidator(VerifiedSubscriptionSchema, value);

const UserPlanDetailsSchema: z.ZodSchema<UserPlanDetails> = z.intersection(
    PlanDetailsSchema,
    z.object(
        {
            isVerified: z.boolean().optional(),
            isSelected: z.boolean().optional(),
        },
        { description: 'UserPlanDetailsSchema' }
    )
);

const UserPlansServicesSchema: z.ZodSchema<UserPlansServices> = z.object(
    {
        client: z.nativeEnum(Partner),
        services: z.string().array(),
        plans: UserPlanDetailsSchema.array(),
    },
    { description: 'UserPlansServicesSchema' }
);

export const userPlansDetailsValidator = (value: unknown): ValidationResult =>
    baseValidator(UserPlansServicesSchema, value);
