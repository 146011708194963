import { Hydrator, HydratorOptions } from './hydrator';

import { Key } from '../storage';
import { getKeys } from '../utils';
import { parse } from 'query-string';

export const queryHydrator: Hydrator = {
    hydrate: (storage, opts?: HydratorOptions) => {
        let hydrated = false;
        const params = parse(window.location.search);
        Object.entries(params).forEach(([key, value]) => {
            const casted = key as Key;
            if (getKeys().includes(casted)) {
                storage.set(casted, value, undefined, opts?.storageOptions);
                hydrated = true;
            }
        });
        return hydrated;
    },
};
