import { ContextKey, Key } from '../storage';
import {
    basePlanDetailsValidator,
    filteredPartnersValidator,
    partnerValidator,
    terminatedPlansValidator,
    unverifiedPlansValidator,
    verifiedPlansValidator,
} from './partner';

import { ValidationResult } from './baseValidator';
import { layoutValidator } from './layout';
import { monitor } from '@soluto-private/mx-monitor';
import { userValidator } from './user';

const validators: Record<ContextKey, (value: unknown) => ValidationResult> = {
    [Key.Client]: partnerValidator,
    [Key.Plans]: basePlanDetailsValidator,
    [Key.Services]: (arr) =>
        ({
            success:
                Array.isArray(arr) && arr.every((v) => typeof v === 'string'),
        } as ValidationResult),
    [Key.Language]: (value) =>
        ({ success: typeof value === 'string' } as ValidationResult),
    [Key.ProductType]: (value) =>
        ({ success: typeof value === 'string' } as ValidationResult),
    [Key.User]: userValidator,
    [Key.UnverifiedPlans]: unverifiedPlansValidator,
    [Key.VerifiedPlans]: verifiedPlansValidator,
    [Key.TerminatedPlans]: terminatedPlansValidator,
    [Key.FilteredPartners]: filteredPartnersValidator,
    [Key.Layout]: layoutValidator,
};

export const validateValue = (key: ContextKey, value: unknown): boolean => {
    const validator = validators[key as Key];
    if (!validator || value == null) {
        return true;
    }

    const res = validator(value);
    if (!res.success) {
        const baseErrorMessage = `Unsupported value for key ${key}`;
        const error = new Error(
            res.message
                ? `${baseErrorMessage} - ${res.message}`
                : baseErrorMessage
        );

        monitor.error(error.message, error, { key, invalidValue: value });
    }

    return res.success;
};
