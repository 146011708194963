import { ValidationResult, baseValidator } from './baseValidator';

import { User } from '@soluto-private/mx-types';
import { z } from 'zod';

const UserSchema: z.ZodSchema<User> = z.object({
    firstName: z.string(),
    language: z.string(),
});

export const userValidator = (value: unknown): ValidationResult =>
    baseValidator(UserSchema, value);
