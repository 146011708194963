import { SafeParseError, SafeParseSuccess, Schema, z } from 'zod';

export type ValidationResult = {
    success: boolean;
    message?: string;
};

export const baseValidator = (
    schema: Schema,
    value: unknown,
    isArray = false
): ValidationResult => {
    const validationRes = isArray
        ? z.array(schema).safeParse(value)
        : schema.safeParse(value);
    const errors = getZodErrors(validationRes);
    return {
        success: validationRes.success,
        message:
            errors?.length > 0
                ? `${schema.description} ${
                      errors.length
                  } error(s): ${errors.join(', ')}`
                : '',
    };
};

const getZodErrors = (
    validationRes: SafeParseSuccess<unknown> | SafeParseError<unknown>
): string[] => {
    const messages: string[] = [];
    if (!validationRes.success) {
        validationRes.error.issues.forEach((issue) => {
            messages.push(
                issue.path?.length > 0
                    ? `[${issue.path.toString()}]: ${issue.message}`
                    : issue.message
            );
        });
    }

    return messages;
};
