import { ContextKey, Storage, StorageOptions, ValidatorFunc } from './storage';
import Cookies, { CookieAttributes } from 'js-cookie';
import { dispatch, getKeys, modifiers, storageEvents } from './utils';

import { monitor } from '@soluto-private/mx-monitor';
import { validateValue } from './validation';

const mandatoryAttributes: CookieAttributes = {
    secure: true,
    sameSite: 'strict',
    // base path is enforced over every record to allow global delete
    // https://github.com/js-cookie/js-cookie#basic-usage
    path: '/',
};

const cookieSetInternal = <T>(
    key: ContextKey,
    value: T,
    validatorFunc: ValidatorFunc<T>,
    opts?: StorageOptions
) => {
    let finalValue: T = value;

    const modifier = modifiers[key];
    if (modifier) {
        finalValue = modifier(value) as T;
    }

    if (validatorFunc && !validatorFunc(finalValue)) {
        const formattedValue =
            typeof finalValue === 'object'
                ? JSON.stringify(finalValue)
                : String(finalValue);
        const error = new Error(
            `mx-context cookies/setInternal failed. The value (${formattedValue}) failed validation for key (${key})`
        );

        monitor.error(error.message, error, { key, invalidValue: finalValue });

        dispatch(storageEvents.contextValidationError);
        dispatch(storageEvents.keyValidationError(key));
    } else {
        Cookies.set(key, JSON.stringify(finalValue), {
            ...opts,
            ...mandatoryAttributes,
        });

        dispatch(storageEvents.contextUpdated);
        dispatch(storageEvents.keyUpdated(key));
        document.dispatchEvent(new Event(storageEvents.contextUpdated));
        document.dispatchEvent(new Event(storageEvents.keyUpdated(key)));
    }
};

const cookieGetInternal = <T>(
    key: ContextKey,
    validatorFunc: ValidatorFunc<T>
): T | undefined => {
    let finalValue: T | undefined;

    let cookieValue: string | undefined;
    try {
        cookieValue = Cookies.get(key);
        if (cookieValue) {
            const value = JSON.parse(cookieValue) as T;
            if (validatorFunc && !validatorFunc(value)) {
                const formattedValue =
                    typeof value === 'object'
                        ? JSON.stringify(value)
                        : String(value);

                const error = new Error(
                    `mx-context cookies/setInternal failed. The value (${formattedValue}) failed validation for key (${key})`
                );

                monitor.error(error.message, error, {
                    key,
                    invalidValue: value,
                });
            } else {
                finalValue = value;
            }
        }
    } catch (ex) {
        const error =
            ex instanceof Error ? ex : new Error('Something went wrong');
        monitor.error(error.message, error, { key, invalidValue: cookieValue });

        Cookies.remove(key, mandatoryAttributes);
    }

    return finalValue;
};

const cookieRemoveInternal = (key: ContextKey) => {
    const value = Cookies.get(key);
    if (value) {
        Cookies.remove(key, mandatoryAttributes);
        dispatch(storageEvents.keyRemoved(key));
    }
};

export const cookieStorage: Storage = {
    set: <T>(
        key: ContextKey,
        value: T,
        validatorFunc: ValidatorFunc<T> = (val) => validateValue(key, val),
        opts?: StorageOptions
    ) => {
        cookieSetInternal(key, value, validatorFunc, opts);
    },
    get: <T>(
        key: ContextKey,
        validatorFunc: ValidatorFunc<T> = (val) => validateValue(key, val)
    ): T | undefined => {
        return cookieGetInternal(key, validatorFunc);
    },
    remove: (key: ContextKey) => cookieRemoveInternal(key),
    removeAll: () => {
        getKeys().forEach((key) => {
            cookieRemoveInternal(key);
        });
    },
};
