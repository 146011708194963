import { ContextKey, Storage, ValidatorFunc } from './storage';
import { dispatch, getKeys, modifiers, storageEvents } from './utils';

import { monitor } from '@soluto-private/mx-monitor';
import { validateValue } from './validation';

const sessionSetInternal = <T>(
    key: ContextKey,
    value: T,
    validatorFunc: ValidatorFunc<T>
) => {
    let finalValue: T = value;

    const modifier = modifiers[key];
    if (modifier) {
        finalValue = modifier(value) as T;
    }

    if (validatorFunc && !validatorFunc(finalValue)) {
        const formattedValue =
            typeof value === 'object'
                ? JSON.stringify(finalValue)
                : String(finalValue);
        const error = new Error(
            `mx-context sessionStorage/setInternal failed. The value (${formattedValue}) failed validation for key ${key}`
        );

        monitor.error(error.message, error, { key, invalidValue: finalValue });

        dispatch(storageEvents.contextValidationError);
        dispatch(storageEvents.keyValidationError(key));
    } else {
        window.sessionStorage.setItem(key, JSON.stringify(finalValue));

        dispatch(storageEvents.contextUpdated);
        dispatch(storageEvents.keyUpdated(key));
        document.dispatchEvent(new Event(storageEvents.contextUpdated));
        document.dispatchEvent(new Event(storageEvents.keyUpdated(key)));
    }
};

const sessionGetInternal = <T>(
    key: ContextKey,
    validatorFunc: ValidatorFunc<T>
): T | undefined => {
    let finalValue: T | undefined;

    let storageItem;
    try {
        storageItem = window.sessionStorage.getItem(key);
        if (storageItem) {
            const value = JSON.parse(storageItem) as T;
            if (validatorFunc && !validatorFunc(value)) {
                const formattedValue =
                    typeof value === 'object'
                        ? JSON.stringify(value)
                        : String(value);

                const error = new Error(
                    `mx-context sessionStorage/getInternal failed. The value (${formattedValue}) failed validation for key (${key})`
                );

                monitor.error(error.message, error, {
                    key,
                    invalidValue: value,
                });
            } else {
                finalValue = value;
            }
        }
    } catch (ex) {
        const error =
            ex instanceof Error ? ex : new Error('Something went wrong');
        monitor.error(error.message, error, { key, invalidValue: storageItem });

        window.sessionStorage.removeItem(key);
    }

    return finalValue;
};

const sessionRemoveInternal = (key: ContextKey) => {
    const value = window.sessionStorage.getItem(key);
    if (value) {
        window.sessionStorage.removeItem(key);
        dispatch(storageEvents.keyRemoved(key));
    }
};

export const sessionStorage: Storage = {
    set: <T>(
        key: ContextKey,
        value: T,
        validatorFunc: ValidatorFunc<T> = (val) => validateValue(key, val)
    ) => {
        sessionSetInternal(key, value, validatorFunc);
    },
    get: <T>(
        key: ContextKey,
        validatorFunc: ValidatorFunc<T> = (val) => validateValue(key, val)
    ): T | undefined => {
        return sessionGetInternal(key, validatorFunc);
    },
    remove: (key: ContextKey) => sessionRemoveInternal(key),
    removeAll: () => {
        getKeys().forEach((key) => {
            sessionRemoveInternal(key);
        });
    },
};
