export const storageEvents = {
    contextUpdated: 'ONESERVICE_CONTEXT_UPDATED',
    contextValidationError: 'ONESERVICE_CONTEXT_VALIDATION_ERROR',
    keyUpdated: (key: string): string => `ONESERVICE_CONTEXT_${key}_UPDATED`,
    keyRemoved: (key: string): string => `ONESERVICE_CONTEXT_${key}_REMOVED`,
    keyValidationError: (key: string): string =>
        `ONESERVICE_CONTEXT_${key}_VALIDATION_ERROR`,
};

class EventEmitter implements EventTarget {
    delegate: DocumentFragment;

    constructor() {
        this.delegate = document.createDocumentFragment();
    }

    addEventListener(
        type: string,
        listener: EventListenerOrEventListenerObject | null,
        options?: boolean | AddEventListenerOptions
    ): void {
        this.delegate.addEventListener(type, listener, options);
    }

    dispatchEvent(event: Event): boolean {
        return this.delegate.dispatchEvent(event);
    }

    removeEventListener(
        type: string,
        callback: EventListenerOrEventListenerObject | null,
        options?: EventListenerOptions | boolean
    ): void {
        this.delegate.removeEventListener(type, callback, options);
    }
}

const emitter = new EventEmitter();

export const listen = (
    event: string,
    listener: EventListenerOrEventListenerObject | null
) => {
    emitter.addEventListener(event, listener);
};

export const mute = (
    event: string,
    listener: EventListenerOrEventListenerObject | null
) => {
    emitter.removeEventListener(event, listener);
};

export const dispatch = (event: string) => {
    emitter.dispatchEvent(new CustomEvent<string>(event));
};
