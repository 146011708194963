import { Hydrator, HydratorOptions } from './hydrator';

import { Key } from '../storage';
import { getKeys } from '../utils';

export type ObjectHydratorOptions = HydratorOptions & {
    records: Record<string, unknown>;
};

export const objectHydrator: Hydrator = {
    hydrate: (storage, opts: ObjectHydratorOptions) => {
        let hydrated = false;
        Object.entries(opts.records).forEach(([key, value]) => {
            const casted = key as Key;
            if (getKeys().includes(casted)) {
                storage.set(casted, value, undefined, opts?.storageOptions);
                hydrated = true;
            }
        });
        return hydrated;
    },
};
